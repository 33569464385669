"use strict";
const COINGECKO_AVAX_LIST = "https://tokens.coingecko.com/avalanche/all.json";
const ABSTRACT_LIST = "https://api.testnets.relay.link/tokenlist?chainId=11124";
const ZERO_LIST = "https://api.relay.link/tokenlist?chainId=543210";
const CYBER_LIST = "https://api.relay.link/tokenlist?chainId=7560";
const BOB_LIST = "https://api.relay.link/tokenlist?chainId=60808";
const SHAPE_LIST = "https://api.relay.link/tokenlist?chainId=360";
export const DEFAULT_INACTIVE_LIST_URLS = [COINGECKO_AVAX_LIST];
export const DEFAULT_ACTIVE_LIST_URLS = [ABSTRACT_LIST, ZERO_LIST, CYBER_LIST, BOB_LIST, SHAPE_LIST];
export const DEFAULT_LIST_OF_LISTS = [...DEFAULT_ACTIVE_LIST_URLS];
